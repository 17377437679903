import React from 'react';
import { Box, TapArea } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import frank from "./images/MuggyFrank.png";
import frankBox from "./images/MuggyFrank-Box.png";
import witch from "./images/D-RexWitch.png";
import witchBox from "./images/D-RexWitch-Box.png";
import grim from "./images/BruceGrim.png";
import grimBox from "./images/BruceGrim-Box.png";
import zombie from "./images/Zombie.png";
import zombieBox from './images/ZombieBox.png';
import mummy from "./images/BoostMummy.png";
import mummyBox from "./images/MummyBoost-Box.png";
import invisibleMan from "./images/InvisibleMan.png";
import invisibleManBox from "./images/InvisibleManBox.jpg";
import ccTeam from "./images/CC-Team.png";
import { Carousel } from 'react-responsive-carousel';
import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

export function AboutSection(props) {

    // eslint-disable-next-line react/prop-types
    const { isTabletOrMobile } = props;

    const renderImage = (img) => {
        return (
            <TapArea onMouseEnter={() => {
                setFocusedCharacter(img.name)
            }} onMouseLeave={() => {
                setFocusedCharacter(null)
            }}>
                <Box display="flex">
                    <Box display="flex" direction='column' alignItems='center' width={250} height={250}>
                        {focusedCharacter === img.name && (
                            <img src={img.boxImage} alt="Selected monster" width={250} height={250}></img>
                        )}
                        {focusedCharacter !== img.name && (
                            <img src={img.image} alt="Monster silhouette" width={250} height={250}></img>
                        )}
                        {img.name}
                    </Box>
                </Box>
            </TapArea>
        )
    }
    
    const renderImageRow = (images) => {
        return (
            <Box display="flex" marginTop={10}>
                {renderImage(images[0])}
                {images.length > 1 && renderImage(images[1])}
                {images.length > 2 && renderImage(images[2])}
            </Box>
        )
    
    }

    const team = {
        'Bruce': {
            image: grim,
            boxImage: grimBox,
            name: 'Bruce',
            title: 'Founder & Director of Business Development',
            description: 'Curator of ideas and design for the Creepy Creatures universe. Lover of sports, retro video games and taking time to explore the outdoors.'
        },
        'Muggy': {
            image: frank,
            boxImage: frankBox,
            name: 'Muggy',
            title: 'Artist & Creative Development',
            description: 'With a distinct artistic style all his own, Muggy has been drawing, painting and letting his unlimited creativity flow through all of his artwork for over 3 decades. His imaginative approach to his craft makes any of his murals, logos or cartoons immediately recognizable. Simply put, he draws dope shit.'
        },
        'Lewiez': {
            image: mummy,
            boxImage: mummyBox,
            name: 'Lewiez',
            title: 'Head of Social Media & Marketing Manager',
            description: 'Engineer and dad of two, but foremost a Web3 OG and Community Guru with a knack for management and planning. Loves engaging with people and making new friends and always has an open door policy.',
        },
        'D-Rex': {
            image: witch,
            boxImage: witchBox,
            name: 'D-Rex',
            title: 'Technical Architect',
            description: 'Coder extraordinaire who also enjoys traveling the globe in her spare time. Can dance with the best of them from ballet to hip-hop.',
        },
        'Monster Mac': {
            image: zombie,
            boxImage: zombieBox,
            name: 'Monster Mac',
            title: 'Quality Control & Monster Expert',
            description: 'Being in the art world for over 30 years (as an artist and art director) has really helped "Monster Mac" garner a keen sense for things that are both spooky & eye popping. When presented the chance to work with his long time friend Muggy, he couldn\'t pass up the opportunity. Especially considering it\'s right up his alley genre wise.'
        },
        'S.PRENKO': {
            image: invisibleMan,
            boxImage: invisibleManBox,
            name: 'S.PRENKO',
            title: 'Community Mod',
            description: 'Creative Consultant, Writer, Mental Health Consultant, and Community Builder with a huge love for art and NFTs. Spends the bulk of his time creepin\' it real by impacting lives through creative writing and speaking in Web3.',
        }
    }

    const [focusedCharacter, setFocusedCharacter] = React.useState(null);
    const renderImageRows = () => (
        <Box>
            {renderImageRow([team['Bruce'], team['Muggy'], team['D-Rex']])}
            {renderImageRow([team['Lewiez'], team['Monster Mac'], team['S.PRENKO']])}
        </Box>
    )

    const renderImageCarousel = () => (
        <div className={styles}>
            <Carousel showStatus={false} showThumbs={false} autoFocus={true}>
                <div>
                    <img src={grim} />
                    <p className="legend">Bruce <br></br> Founder & Director of Business Development</p>
                </div>
                <div>
                    <img src={frank} />
                    <p className="legend">Muggy <br></br>Artist & Creative Development </p>
                </div>
                <div>
                    <img src={mummy} />
                    <p className="legend">Lewiez <br></br>Head of Social Media & Marketing Manager</p>
                </div>
                <div>
                    <img src={witch} />
                    <p className="legend">D-Rex <br></br> Technical Architect</p>
                </div>
                <div>
                    <img src={zombie} />
                    <p className="legend">Monster Mac <br></br> Quality Control & Monster Expert</p>
                </div>
                <div>
                    <img src={invisibleMan} />
                    <p className="legend">S.PRENKO <br></br> Community Mod</p>
                </div>
            </Carousel>
        </div>
    )
    let aboutChunk = null;
    if (focusedCharacter !== null) {
        aboutChunk = (
            <Box height={500} width={500}>
                <div className="aboutName">
                    {team[focusedCharacter].name}<br></br>
                </div>
                <div className="aboutTitle">
                    {team[focusedCharacter].title}<br></br><br></br>
                </div>
                <div className="aboutDescription">
                    {team[focusedCharacter].description}<br></br>
                </div>
            </Box>
        )
    }
    if (isTabletOrMobile) {
        return (
                <Box display="flex" direction="column" alignItems='center'>
                    <img src={ccTeam} alt="Creepy creatures team" width={250}></img>
                    <div className="aboutMobile">
                        Creepy Creatures is a unique collection of 13,000 NFTs living on the Ethereum blockchain. 13 characters in total make up our OG release with each creature totaling 1,000 NFTS. <br /><br />
                        Together we&apos;re building a like minded group of web3 enthusiasts who love dope art (shout out to Muggy), innovative irl utility and a fully functioning universe where you&apos;re in charge of telling the story of your Creepy Creatures. 
                    </div>
                    <div className="carouselMobile">
                        {renderImageCarousel()}
                    </div>
                </Box>
        );
    } else {
        return (
            <Box display="flex" direction={isTabletOrMobile ? "column": "row"} alignItems='top' height={900} padding={10}>
                <div style={{
                        backgroundImage: "url('https://ccwebsite-f0512.web.app/Moon2.png')",
                        backgroundSize: '500px',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'top',
                    }}>
                    <Box display="flex" direction="column">
                        <img src={ccTeam} alt="Creepy creatures team" width="90%"></img>
                        <Box>
                            {aboutChunk !== null && aboutChunk}
                            {aboutChunk === null && (
                                <Box  minWidth={isTabletOrMobile ? 0: 400} width={500} height={500} display="flex" alignContent='center'>
                                    <div className="about">
                                        Creepy Creatures is a unique collection of 13,000 NFTs living on the Ethereum blockchain. 13 characters in total make up our OG release with each creature totaling 1,000 NFTS. <br /><br />
                                        Together we&apos;re building a like minded group of web3 enthusiasts who love dope art (shout out to Muggy), innovative irl utility and a fully functioning universe where you&apos;re in charge of telling the story of your Creepy Creatures. 
                                    </div>
                                </Box>
                            )}
                        </Box>
                        {isTabletOrMobile && (
                            <div>
                                {renderImageCarousel()}
                            </div>
                        )}
                    </Box>
                </div>
                {!isTabletOrMobile && (
                    <Box display="flex" direction='column'>
                        {renderImageRows()}
                    </Box>
                )}
            </Box>
        )
    }
}