import whitelistAddresses from '../config/whitelist.json';
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

export default new class Whitelist {

  constructor() {
    const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
      
    this.merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
  }

  getMerkleTree() {
    if (this.merkleTree === undefined) {
      const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
      
      this.merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
    }

    return this.merkleTree;
  }

  getProofForAddress(address)
  {
    return this.getMerkleTree().getHexProof(keccak256(address));
  }

  // contains(address)
  // {
  //   return this.getMerkleTree().getLeafIndex(Buffer.from(keccak256(address))) >= 0;
  // }
};