import React from 'react';
import { Modal, Box } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import { useConnect, useAccount } from 'wagmi';

// eslint-disable-next-line react/prop-types
export function ConnectWalletModal({ setConnectWalletModalOpen }) {
    const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
    const { address } = useAccount();
    if (address) {
        setConnectWalletModalOpen(false);
    }

    console.log(connectors)

    return (
        <Modal heading="Choose a wallet to connect:" onDismiss={()=> setConnectWalletModalOpen(false)}>
            <Box display="flex" direction="row" padding={2} marginX={2} alignItems="center" justifyContent='center'>
                <div>
                {connectors.map((connector) => (
                    <button
                        disabled={!connector.ready}
                        key={connector.id}
                        onClick={() => connect({ connector })}
                        >
                        {connector.name}
                        {!connector.ready && ' (download extension to use)'}
                        {isLoading &&
                            connector.id === pendingConnector?.id &&
                            ' (connecting)'}
                    </button>
                ))}
            
                {error && <div>{error.message}</div>}
                </div>
            </Box>
        </Modal>
    )
}