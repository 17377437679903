/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { ethers } from "ethers";
import './App.css';
import Whitelist from './utils/Whitelist.js';
import VicList from './utils/VicList.js';
import whitelistAddresses from './config/whitelistAddresses.js';
import vicListAddresses from './config/vicListAddresses.js';
import { Box, Flex, Link, Button, Modal, TextField, Text, TextArea } from 'gestalt';
import creepyCreaturesNFT from './utils/CreepyCreaturesOGs.json';
import 'gestalt/dist/gestalt.css';
import ccLogo from "./images/CC-Font.png";
import ccBox from './images/CC-Box.png';
import creepyCastle from './images/creepyCastle.png';
import background from './images/backdrop3.png';
import { ProjectRoadmap } from './ProjectRoadmap';
import { AboutSection } from './AboutSection';
import { Utility } from './Utility';
import { FaqSection } from './FaqSection';
import { ConnectWalletModal } from './ConnectWalletModal';
import { ExternalLinksPanel } from './ExternalLinksPanel';
import { useMediaQuery } from 'react-responsive';
import firebase from "firebase/compat/app";
import { getDatabase, ref, child, push, update } from "firebase/database";
import "firebase/auth";
import "firebase/compat/database"
import { useAccount } from 'wagmi'

const executeScroll = (ref) => ref.current.scrollIntoView({ behavior: 'smooth' });  

const headerLinks = (links) => (
  <Box marginX={10}>
    <Flex display="flex" gap={10} width="70%" alignItems='center'>
      {links.map((link, index) => (
        <span color="white" key={index} className="link">
          <Link onClick={() => executeScroll(link.ref)}>{link.text}</Link>
        </span>
      ))}
    </Flex>
  </Box>
)

function WrappedWagmiApp() {
  const CONTRACT_ADDRESS = "0xfa27d06d817205e6B3702594B996f3B0A476D022";
  const [modalOpen, setModalOpen] = useState(false);
  const [wlModalOpen, setWlModalOpen] = useState(false);
  const [wlConfirmationModalOpen, setWlConfirmationModalOpen] = useState(false);
  const [wlReason, setWlReason] = useState(false);
  const [email, setEmail] = useState(null);
  const [twitter, setTwitter] = useState(null);
  const [transactionMinedModalOpen, setTransactionMinedModalOpen] = useState(false);
  const [miningModalOpen, setMiningModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState(null);
  const [numberToMint, setNumberToMint] = useState(3);
  const [wlApplicationModalOpen, setWlApplicationModalOpen] = useState(false);
  const [walletAddressRegistered, setWalletAddressRegistered] = useState(false);
  const [connectWalletModalOpen, setConnectWalletModalOpen] = useState(false);
  const mintStage = 'generalPublic';
  const vicPrice = .03;
  const whitelistPrice = .035;
  const generalPublicPrice = .03;

  const headingTitle = mintStage === 'vic' ? 'Claim your Creepy Creatures, V.I.C.!' : 'Claim your Creepy Creatures';
  const mintModalHeading = (
      <Box padding={5}>
          <div className='modalHeader'>
            {headingTitle}
          </div>
      </Box>
  );

  const { address } = useAccount();

  let isUserInVic = false;
  let isUserInWL = false;

  const vicsToLower = vicListAddresses.map(v => v.toLowerCase());
  const whitelistToLower = whitelistAddresses.map(v => v.toLowerCase());
  
  if (address && vicsToLower.includes(address.toLowerCase())) {
    isUserInVic = true;
  }
  if (address && whitelistToLower.includes(address.toLowerCase())) {
    isUserInWL = true;
  }

  // Validations
  const [emailError, setEmailError] = useState(null);
  const [twitterEmpty, setTwitterEmpty] = useState(false);
  const [reasonEmpty, setReasonEmpty] = useState(false);
  const mintingEnabled = true;
  let maxAllowed;
  if (mintStage === 'vic') {
    maxAllowed = 12;
  } else if (mintStage === 'whitelist') {
    maxAllowed = 6;
  } else {
    maxAllowed = 10;
  }
  const maxErrorMessage = `You may only mint a maximum of ${maxAllowed} Creepy Creatures.`;
  const getErrorMessage = () => {
    if (parseInt(numberToMint, 10) === 0 || numberToMint === '') {
      return 'You must mint at least one Creepy Creature!'
    } else if (numberToMint > maxAllowed) {
      return maxErrorMessage
    }
    return '';
  }

  const getEmailErrorMessage = () => {
    if (!email) {
      return "Oops! This field can't be blank"
    } else if (!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )) {
      return "Please enter a valid email address"
    } else {
      return null;
    }
  }

  const validateAndSubmitWlModal = () => {
    const emailErrorMsg = getEmailErrorMessage();
    setEmailError(emailErrorMsg);
    setTwitterEmpty(!twitter);
    setReasonEmpty(!wlReason);

    if (!emailErrorMsg && !!twitter && !!wlReason) {
      // submit
      setWlModalOpen(false);
      const db = getDatabase();
      const newItemKey = push(child(ref(db), '/whitelist/')).key;
      const updates = {};
      updates['whitelist/' + newItemKey] = {
        email: email,
        twitter: twitter,
        reason: wlReason,
        approved: false,
        blocked: false,
        walletAddress: address,
      };
      update(ref(db), updates);
      setWalletAddressRegistered(true);
      setWlConfirmationModalOpen(true);
    }

  }

  // eslint-disable-next-line no-unused-vars
  const checkForPermissionsAndOpenModal = async () => {

    const { ethereum } = window;
  
    if (ethereum) {
      const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, creepyCreaturesNFT.abi, signer);
      if (mintStage === 'notStarted') {
        if (isUserInVic) {
            setErrorModalMessage('Welcome, VIC! VIC List not enabled yet, check back soon!');
        } else if (isUserInWL) {
            setErrorModalMessage('Welcome, WL member! Whitelist not enabled yet, check back soon!');
        } else {
            setErrorModalMessage('Minting stages begin October 31! Check back then and follow along in our Discord.')
        }
      } else if (mintStage === 'vic') {
        if (isUserInVic) {
          const vicListEnabled = await connectedContract.vicListMintEnabled();
          if (!vicListEnabled) {
            setErrorModalMessage('Welcome, VIC! VIC List not enabled yet, check back soon!');
          } else {
            const checkNumberMinted = await connectedContract.vicListMintedBalance(address);
            const numberMintedConverted = parseInt(checkNumberMinted._hex, 16);
            if (numberMintedConverted >= maxAllowed) {
              setErrorModalMessage('Congratulations! You have already minted the max number of creatures for this stage.')
            } else {
              setModalOpen(true);
            }
          }
        } else {
          setErrorModalMessage('Oops! You are not a VIC. Please check back for the next minting stage!')
        }
      } else if (mintStage === 'whitelist') {
        if (isUserInWL) {
          const whitelistEnabled = await connectedContract.whitelistMintEnabled();
          debugger;
          if (!whitelistEnabled) {
            setErrorModalMessage('Welcome, WL member! Whitelist not enabled yet, check back soon!');
          } else {
            const checkNumberMinted = await connectedContract.whitelistMintedBalance(address);
            const numberMintedConverted = parseInt(checkNumberMinted._hex, 16);
            if (numberMintedConverted >= maxAllowed) {
              setErrorModalMessage('Congratulations!! You have already minted the max number of creatures for this stage.')
            } else {
              setModalOpen(true);
            }
          }
        } else {
          setErrorModalMessage('Oops! You are not on the whitelist.')
        }
      } else {
        const contractPaused = await connectedContract.paused()
        if (contractPaused) {
          setErrorModalMessage('General public minting begins at 1pm PST Nov 1!')
        } else {
          setModalOpen(true);
        }
      }

    }
  }

  const errorModal = (
    <Box>
      <Modal type="alert" closeOnOutsideClick={true} onDismiss={() => setErrorModalMessage(null)}>
        <Box display="flex" justifyContent='center' padding={2} marginX={2}>
          <div className="radio">
            {errorModalMessage}
          </div>
        </Box>
      </Modal>
    </Box>
  )

  const creatureText = numberToMint === 1 ? 'creature' : 'creatures';
  const awaitText = numberToMint === 1 ? 'awaits' : 'await';
  const checkOutText = numberToMint === 1 ? 'it' : 'them';
  let phaseBasePrice = generalPublicPrice;
  if (mintStage === 'vic') {
    phaseBasePrice = vicPrice;
  } else if (mintStage === 'whitelist') {
    phaseBasePrice = whitelistPrice;
  } else if (mintStage === 'generalPublic') {
    phaseBasePrice = generalPublicPrice;
  }
  const totalPrice = parseInt(numberToMint, 10) * phaseBasePrice;
  const totalPriceFormatted = totalPrice.toFixed(3);

  const nonVicModalBody = (
    <Box display="flex" alignItems="end"> 
      <Box paddingX={2} display="flex" alignItems="end">
        <TextField errorMessage={getErrorMessage()} value={numberToMint} onChange={({value}) => {
        setNumberToMint(value)
        }}/>
        <div className="radio">
          {numberToMint > 0 && `(${numberToMint} ${creatureText}, ${totalPriceFormatted} eth)`}
        </div>
      </Box>
  </Box>
  )
  const vicModalBody = (
    <Box>
      <div className="radio">
        <label>
          <input
            type="radio"
            value={3}
            checked={numberToMint === 3}
            onChange={() => (setNumberToMint(3))}
          />
          Mint 3 Creepy Creatures (0.09 ETH total)
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            type="radio"
            value={6}
            checked={numberToMint === 6}
            onChange={() => (setNumberToMint(6))}
          />
          Mint 6 Creepy Creatures (.18 ETH total)
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            type="radio"
            value={9}
            checked={numberToMint === 9}
            onChange={() => (setNumberToMint(9))}
          />
          Mint 9 Creepy Creatures (.27 ETH total)
        </label>
      </div>
      <div className="radio">
        <label>
          <input
            type="radio"
            value={12}
            checked={numberToMint === 12}
            onChange={() => (setNumberToMint(12))}
          />
          Mint 12 Creepy Creatures (.36 ETH total)
        </label>
      </div>
    </Box>
  )
  const modal = (
    <Box>
        <Modal closeOnOutsideClick={true} heading={mintModalHeading} size="lg" onDismiss={() => setModalOpen(false)}>
          <Box paddingX={5} paddingY={2}>
            {mintStage === 'vic' && vicModalBody}
            {mintStage !== 'vic' && nonVicModalBody}
            <Box paddingY={1}/>
            <Box display="flex" justifyContent='end'>
                <Button color="red" text="MINT" disabled={getErrorMessage() !== ''} onClick={() => askContractToMintNft()}/>
                <Button color="gray" text="cancel" onClick={() => setModalOpen(false)}/>
            </Box>
          </Box>
        </Modal>
    </Box>
  );

  const transactionMinedHeading = (
    <Box padding={5}>
        <div className='modalHeader'>
            Your Creepy {creatureText} {awaitText}! Head to <a href='https://opensea.io/collection/creepy-ogs'>OpenSea</a> to check {checkOutText} out...
        </div>
    </Box>
);

// TODO these might allow us to get the token ID 
// const receipt = await web3.eth.getTransactionReceipt(hash)
// const tokenId = Web3.utils.hexToNumber(receipt.logs[0].topics[3])
  const transactionMinedModal = (
    <Box>
      <Modal heading={transactionMinedHeading} closeOnOutsideClick={true} size="sm" onDismiss={() => setTransactionMinedModalOpen(false)}>
        <Box paddingX={5} paddingY={2} display="flex" justifyContent='center'>
          <img src={ccBox} alt="Creepy Crate" width={200} height={200}></img>
        </Box>
      </Modal>
  </Box>
  )

  const askContractToMintNft = async () => {  
    try {
      const { ethereum } = window;
  
      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, creepyCreaturesNFT.abi, signer);
        let checkNumberMinted;
        if (mintStage === 'vic') {
          checkNumberMinted = await connectedContract.vicListMintedBalance(address);
        } else if (mintStage === 'whitelist') {
          checkNumberMinted = await connectedContract.whitelistMintedBalance(address);
        } else if (mintStage === 'generalPublic') {
          checkNumberMinted = 0;
        }
        const numberMintedConverted = parseInt(checkNumberMinted._hex, 16);
        if (numberMintedConverted >= maxAllowed) {
          alert('You have already minted the maximum number of Creepy Creatures allowed at this stage.')
        } else if (numberMintedConverted + parseInt(numberToMint, 10) > maxAllowed) {
          alert(`You are trying to mint more than the allowed limit at this stage. Only ${maxAllowed} are allowed.`);
        } else {
          // allow the mint!
          console.log("Going to pop wallet now to pay gas...")
          const finalNum = parseInt(numberToMint, 10) * phaseBasePrice;
          const finalNumFixed = finalNum.toFixed(6);
          // const finalNumString = finalNumFixed.toString();
          let nftTxn;
          const integerNumberToMint = parseInt(numberToMint, 10);
          if (mintStage === 'vic') {
            nftTxn = await connectedContract.vicMint(integerNumberToMint, VicList.getProofForAddress(address), {value: ethers.utils.parseEther(finalNumFixed)});
          } else if (mintStage === 'whitelist') {
            nftTxn = await connectedContract.whitelistMint(integerNumberToMint, Whitelist.getProofForAddress(address), {value: ethers.utils.parseEther(finalNumFixed)});
          } else {
            nftTxn = await connectedContract.mint(integerNumberToMint, {value: ethers.utils.parseEther(finalNumFixed)});
          }
    
          setModalOpen(false);
          setMiningModalOpen(true);
          await nftTxn.wait();
          
          console.log(`Mined, see transaction: <network>${nftTxn.hash}`);
          setMiningModalOpen(false);
          setTransactionMinedModalOpen(true);
        }
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      setMiningModalOpen(false);
      if (error.toString().indexOf('code=ACTION_REJECTED') > -1) {
        alert('Transaction rejected by user')
      } else {
        alert(error);
        // alert('Mint unsuccessful :( are you on the whitelist?');
      }
      console.log(error)
    }
  }

//   const checkIfWalletIsConnected = async () => {
//     /*
//     * First make sure we have access to window.ethereum
//     */
//     const { ethereum } = window;

//     if (!ethereum) {
//       alert("MetaMask not detected. Download MetaMask to connect your wallet!");
//       return;
//     } else {
//       console.log("We have the ethereum object", ethereum);
//     }

//     const accounts = await ethereum.request({ method: 'eth_accounts' });
//     /*
//     * User can have multiple authorized accounts, we grab the first one if it's there!
//     */
//     if (accounts.length !== 0) {
//       const account = accounts[0];
//       console.log('setting current account to ' + account);
//       // TODO set current account here
//       firebase.database().ref('whitelist/').orderByChild('walletAddress').equalTo(account).once('value').then((snapshot) => {
//         if (snapshot.val() != null) {
//           setWalletAddressRegistered(true);
//           console.log('account is already in DB')
//         } else {
//           console.log('setting wallet address registered to false');
//           setWalletAddressRegistered(false);
//         }
//       })
//     } else {
//       console.log("No authorized account found");
//       console.log('setting wallet address registered to false');
//       setWalletAddressRegistered(false);
//     }
//   }

  // const connectWalletDeprecated = async () => {
  //   try {
  //     const { ethereum } = window;

  //     if (!ethereum) {
  //       alert("MetaMask not detected. Download MetaMask to connect your wallet!");
  //       return;
  //     }

  //     /*
  //     * Fancy method to request access to account.
  //     */
  //     const accounts = await ethereum.request({ method: "eth_requestAccounts" });

  //     /*
  //     * Boom! This should print out public address once we authorize Metamask.
  //     */
  //     console.log("Connected", accounts[0]);
  //     if (accounts[0]) {
  //       setCurrentAccount(accounts[0]); 
  //       setWlModalOpen(true);
  //     }

  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // eslint-disable-next-line no-unused-vars
  const renderNotConnectedContainer = () => (
    <Button onClick={() => setConnectWalletModalOpen(true)} text="Connect to Wallet"/>
  );

  useEffect(() => {
    if (address) {
      firebase.database().ref('whitelist/').orderByChild('walletAddress').equalTo(address).once('value').then((snapshot) => {
        if (snapshot.val() != null) {
          setWalletAddressRegistered(true);
        } else {
          setWalletAddressRegistered(false);
        }
      })
    }
  }, [address])

  const aboutRef = React.useRef(null);
  const roadmapRef = React.useRef(null);
  const faqRef = React.useRef(null);
  const utilityRef = React.useRef(null);

  const links = [
    { text: 'ABOUT', url: 'https://ccwebsite-f0512.web.app/', ref: aboutRef},
    { text: 'ROADMAP', url: 'https://ccwebsite-f0512.web.app/', ref: roadmapRef},
    { text: 'UTILITY', url: 'https://ccwebsite-f0512.web.app/', ref: utilityRef},
    { text: 'FAQs', url: 'https://ccwebsite-f0512.web.app/', ref: faqRef},
  ];

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
  const isTabletOrMobileLogoWidth = isTabletOrMobile ? 160: 350;
  const isTabletOrMobileLogoHeight = isTabletOrMobile ? 91 : 200;

  const appToReturn = () => {
    if (connectWalletModalOpen) {
      return <ConnectWalletModal setConnectWalletModalOpen={setConnectWalletModalOpen}/>
    }
    if (miningModalOpen) {
      return (
        <div className="App">
            <Box height={50} />
            <Box display="flex" direction="column" justifyContent='center' alignContent='center' alignItems='center'>
              <div className='about'>
                Minting your Creepy {creatureText}...
              </div>
              <img src={creepyCastle} width={600} height={600} alt="Creepy Castle"></img>
            </Box>
        </div>      
      )
    } else if (wlConfirmationModalOpen) {
      return (
        <div className="App" type="alert">
          <Modal heading="Thanks for applying to the WL!" onDismiss={()=> setWlConfirmationModalOpen(false)}>
            <Box display="flex" direction="column" padding={2} marginX={2}>
              <Text>You submitted a WL application with wallet address {address}.</Text>
              <br></br>
              <Text>Check back in a few days to verify your status!</Text>
              <Box display="flex" justifyContent='end'>
                <Button text="OK, got it!" onClick={() => setWlConfirmationModalOpen(false)}></Button>
              </Box>
            </Box>
          </Modal>
      </div> 
      )
    } else if (wlApplicationModalOpen) {
      return (
        <div className="App" type="alert">
          <Modal heading="Apply to the WL" onDismiss={()=> setWlApplicationModalOpen(false)}>
            <Box display="flex" direction="column" padding={2} marginX={2}>
              <Text>Please visit the new tab to fill out our whitelist application form.</Text>
              <br></br>
              <Text>Check back on the website in a few days to verify your status!</Text>
              <Box display="flex" justifyContent='end'>
                <Button text="OK, got it!" onClick={() => setWlApplicationModalOpen(false)}></Button>
              </Box>
            </Box>
          </Modal>
      </div> 
      )
    } else if (wlModalOpen) {
      if (walletAddressRegistered) {
        return (
          <div className="App">
            <Modal heading="Your submission was received." onDismiss={()=> setWlModalOpen(false)}>
              <Box display="flex" direction="column" padding={2} marginX={2}>
                <Text>You have already submitted a WL request with wallet address {address}.</Text>
                <br></br>
                <Text>Check back in a few days to verify your status!</Text>
                <Box display="flex" justifyContent='end'>
                  <Button text="OK, got it!" onClick={() => setWlModalOpen(false)}></Button>
                </Box>
              </Box>
            </Modal>
        </div> 
        )
      } else {
        return (
          <div className="App">
            <Modal heading="Get on the whitelist!" onDismiss={()=> setWlModalOpen(false)}>
              <Box display="flex" direction="column" padding={2} marginX={2}>
                <Text>You are registering for the Creepy Creatures whitelist with wallet address {address}.</Text>
                <br></br>
                <TextField label="Email" autocomplete="email" text={email} onChange={({ value }) => setEmail(value)} errorMessage={emailError}></TextField>
                <br></br>
                <TextField label="Twitter handle" text={twitter} onChange={({ value }) => setTwitter(value)} errorMessage={twitterEmpty ? "This field can't be blank!" : null}/>
                <br></br>
                <TextArea label="Please write a 2-sentence minimum on why you want to be on the Creepy Creatures whitelist!" text={wlReason} onChange={({value}) => setWlReason(value)} errorMessage={reasonEmpty ? "This field can't be blank!" : null}></TextArea>
                <Box display="flex" justifyContent='end'>
                  <Button text="Cancel" onClick={() => setWlModalOpen(false)}></Button>
                  <Button color="red" text="Submit" onClick={() => validateAndSubmitWlModal()}></Button>
                </Box>
              </Box>
            </Modal>
          </div>      
        )
      }
    } else {
        // let classNames = '';
        // if (isTabletOrMobile) {
        //   classNames = 'mobileContainer';
        // }
        return (
          <body>
            <div className="App">
              {errorModalMessage && errorModal}
              {modalOpen && modal}
              {transactionMinedModalOpen && transactionMinedModal}
              <Box padding={isTabletOrMobile ? 0: 0}>
                <Flex direction="column">
                    <Box padding={isTabletOrMobile? 5: 0} display="flex" alignItems="center" justifyContent="between" alignContent='center'>
                      <img src={ccLogo} alt="Logo" width={isTabletOrMobileLogoWidth} height={isTabletOrMobileLogoHeight}></img>
                      <Flex display="flex" direction="column" alignItems="center" gap={4}>
                        {!isTabletOrMobile && headerLinks(links)}
                        <Flex maxHeight="50%" marginTop={5} gap={5}>
                          {/* {!isUserInVic && !isUserInWL && (
                            <Button
                              text={walletAddressRegistered ? "WL pending" : "Join WL"}
                              onClick={() => {
                              if (!address) {
                                  setConnectWalletModalOpen(true);
                              } else {
                                  setWlModalOpen(true);
                              }
                              }}
                            />
                          )} */}
                          {mintingEnabled && (
                            !address ? (
                              renderNotConnectedContainer()
                            ) : (
                              <Button
                                onClick={() => checkForPermissionsAndOpenModal()}
                                text={"CLAIM"}
                                disabled={!mintingEnabled}
                              />
                            )
                          )}
                        </Flex>
                      </Flex>
                      {/* <Box display="flex" alignContent='center' alignItems='center'> */}
                      <Box display="flex" direction="column" alignItems='center'>
                        <ExternalLinksPanel />
                      </Box>
                    </Box>
                  <Box>
                    <Box>
                      <div className='fade-in-image'>
                        <img src={background} width="100%"/>
                      </div>
                    </Box>
                    <Box height={200} />
                    <Box paddingY={2} ref={aboutRef} display="flex" justifyContent="center">
                    </Box>
                    <AboutSection isTabletOrMobile={isTabletOrMobile}/>
                  </Box>
                  <Box padding={10}>
                    <Box height={isTabletOrMobile ? 200: 500} />
                    <Box marginTop={6} paddingY={2} ref={roadmapRef} display="flex" justifyContent="center"/>
                      <ProjectRoadmap />
                    <Box height={isTabletOrMobile ? 200: 500} />
                    <Box ref={utilityRef}>
                      <Utility />
                    </Box>
                    <Box height={isTabletOrMobile ? 200: 500} />
                    <Box marginTop={6} paddingY={2} ref={faqRef} display="flex" justifyContent="center"/>
                    <Box display="flex" justifyContent='center' alignItems='center'>
                        <Box width="100%">
                          <FaqSection isTabletOrMobile={isTabletOrMobile}/>
                        </Box>
                    </Box>
                  </Box>
                </Flex>
              </Box>
              <Box width={document.documentElement.clientWidth - 40} display="flex" justifyContent='end'>
                ⓒ 2023 J.D.A. Labs LLC
              </Box>
              <Box height={20} />
            </div>
          </body>
        );
    }
  }
  return appToReturn();
}

export default WrappedWagmiApp;