import React from 'react';
import { Box } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import roadmap2023 from './images/roadmap2023.png';
import roadmap2024 from './images/roadmap2024.jpg';

export function ProjectRoadmap() {

    return (
        <Box display="flex" direction="column" alignContent='center' justifyContent='center'>
            <img src={roadmap2023} width="100%"/>
            <Box height={50} />
            <img src={roadmap2024} width="100%"/>
        </Box>
    )
}