import React from 'react';
import { Box } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import physicalArt from "./images/physicalArt.png";
import poker from './images/NEW-Poker-Utility.png'
import staking from "./images/StakingAKABury.png";
import creepyCreaturesUtility from "./images/CreepyCreaturesUtility.png";
import { useMediaQuery } from 'react-responsive';

export function Utility() {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' })
    const isTabletOrMobileImageWidth = isTabletOrMobile ? 160: 550;
    const isTabletOrMobileImageHeight = isTabletOrMobile ? 120 : 500;
    const physicalImageWidth = isTabletOrMobile ? 160 : 550;
    const physicalImageHeight = isTabletOrMobile ? 215 : 741;
    return (
        <Box display='flex' justifyContent='center' alignItems='center'>
            <Box display='flex' direction='column' alignItems='center'> 
                <img src={creepyCreaturesUtility} alt="Creepy creatures utility" width={isTabletOrMobile ? 250: 600} height={isTabletOrMobile ? 38 : 150}></img>
                <Box height={isTabletOrMobile ? 45 : 100}></Box>
                <Box display="flex" direction="column" alignItems="center">
                    <img src={physicalArt} width={physicalImageWidth} height={physicalImageHeight}/>
                    <Box height={isTabletOrMobile ? 45 : 100}></Box>
                    <Box display="flex">
                        <img src={staking} width={isTabletOrMobileImageWidth} height={isTabletOrMobileImageHeight}/>
                        <Box width={isTabletOrMobile ? 40 : 80}></Box>
                        <img src={poker}width={isTabletOrMobileImageWidth} height={isTabletOrMobileImageHeight} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}