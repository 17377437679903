export const vicListAddresses = [
  "0xc91eD7F3212dEb66f44C2FC4d092829F7d587e29",
  "0xF24922f17A80D034E808C99343bCe882B2a5cb3C",
  "0x23d80b2b5057e824Ee3A1bE504c3b56B5e58aB78",
  "0x71943096a0b5b0c0CA002B8598620aa71e142c25",
  "0xD8daa1d4B1eF203D94745FbB1EBAD0c56bF411a2",
  "0x26619Ba44efd6190044e3c572F5109F9d53450fe",
  "0xf76AB7dE36335F37b1641bD5CCE0409AFBE69D9d",
  "0x8B6115DcC89cD3f814092Fe89605bed313A0e7df",
  "0xa93b7021e75f31fF70Bd491BeA422C61c5fA7DfA",
  "0x25dB51663C197d2ccD3e3CB838f35b3a94C0DbF4",
  "0x45998ABBCCA2892a3f0ee28ef95e6589F0292Eb8",
  "0xB87C0CC3208Fe03Fa7AD464E97869f6D81d33343",
  "0xec7741defcfDd6A806e0C49e008Be2Dc5c0C5eE7",
  "0xAe1e9236ae7dECfaa28BDb2C72186df8536C7323",
  "0x6A2CC6fce4454B64e621273e1881c5a3533E2b78",
  "0x0571Ff9477741A11C9278043fAAbbA1293771D44",
  "0x1948a40d341899DdC8a41f88De1A52e3Ca0538CA",
  "0x3F7FbC0844b5Ad5d9d18D77D7A26692732025330",
  "0xeF8a5aC409fFF61170191ca41527F92BfCc47096",
  "0x989c9D43da3a3908e79872127698301c4747e5fa",
  "0xf9de61C2cC1047431c6ad9F942c1E5162F8590c9",
  "0x42081e6E772aB55Bf3A7Ad3C27d22c533e4273bB",
  "0x6547CB8dbc4473eac590aDE79EE5B43262f48771",
  "0xF957A542D265dE21C1B1a2a938ae55E9258B36A7",
  "0x141F94aBfCB51391bEc685eAE085E8e2184ebf66",
  "0x3d90443b5cFD638d96787c33FD0F14Ac18599D4b",
  "0xD363E1BB52cf591c3f5975Ac9225Eb59B934c59b",
  "0x914D14e0394a8138C12F8817c063F465d1FDdf61",
  "0xd0Df45EA1a9782711C5f88bec0bE27402060Cd8e",
  "0x8b835e35838448a8A29Be15E926D99E9FB040822",
  "0x5c019d29e67BeD0135845a5b747ae3E705275504",
  "0xD9136beBBc8b9F43058DF1E52FE3e9CBbcdc8c30",
  "0x8a451C0373AA1cCeB6BDb527c16481f3428ECF49",
  "0x404747A3e1A00F9E0f059bC72D7D68AA46756c2a",
  "0x9E27dbCE91b08Af645e96A3B6884cC4E03dA6Ac2",
  "0x28d5d47749a69279bc9ac4e91e89ba7c0c7d3516",
  "0x16E315e37cDEda413D22a6899FFF67A4B001D91B",
  "0x8A4667f6019A7391D7562EbDeeaa2836Dbf89309",
  "0x667cE4c3f72B15a6b504C25046De5B7f84BD6c6F",
  "0xBDf60063524fDA4cEB36e3c7A24f47E26D154Dbc",
  "0xa33570403268651ed99CCB5Fb38bBA0294D6CB68",
  "0x83a228501093c53f7aD65AB94BF3A2Ebd007Bc72",
  "0x5c295CA8CFFc690F586e650f9e7eb78fE48aC8C9",
  "0x0FF917A200A2aaEB90490AA7feC9771459d0f56a",
  "0xd474047cE5F8Ad4fb8582757028388E4D7006390",
  "0x7Bc6487063b160CB8AB92D33289f198038081Bf9",
  "0x9FD72e682F7989aF5fA66A3825d2eFe13694bc79",
  "0x274e4971A79e30f3e62C3E5b896c868b6bDA2572",
  "0x17d7890eFa330b2a29BA8CC7daAFd136b54Ef230",
  "0x653890DE797742D783738c97522Ab71A88A31200",
  "0x40f0D8104ae5C9b9D7185Dbf1d65c2d40CA1565f",
  "0xb7478fc5f332ca815b990f853c2c507500945582",
  "0xaceee37D3F70E407631277FDDC5fdB1CBc827001",
  "0xF29D1d057a6a05B673f47C20E81596b8bab44447",
  "0x4A76490fE094C487A9678c27372175F4e6340B88",
  "0x159F7A2E758D8F16615D6Ce6b35040d4C629a38f",
  "0x40EC4D62d9DE40EdC5Eb8257A5402a2712ADB228",
  "0xaceee37D3F70E407631277FDDC5fdB1CBc827001",
  "0xe03D0416Ca360d5C8a99C7b55F2eB98766EB2Fab",
  "0xc784f18ee8bCb9e1E75c048c452243B0828a5753",
  "0x45D86f87F9F063d672eDC2C4FB03FF7881135520",
  "0x08b36cA98E15E6A8605e8323669eBC55B639e14F",
  "0xc78E37aC53675DDFce94663db8ad67FBd1C0Fc81",
  "0xE102A7b1a5aC4299d1BE71DB009add30151FA6E6",
  "0x0E2ce3C625Ca5ceb3EBe8D834e0980822415c35B",
  "0x25a1cfac8c9eadab7c12fb59d54144593aa96436",
  "0xD134915bC626B83C2a2A94A36Cfc4b14D0C6b2dE"
]

export default vicListAddresses;