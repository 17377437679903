import React from 'react';
import { Box, IconButton, TapArea } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import faq from "./images/FAQ.png";

const formattedQuestion = (question, answer, setOpenItem, index, isOpen) => {
    return (
        <TapArea onTap={() => setOpenItem(index)} key={index}>
            <Box paddingX={4} paddingY={2}>
                <Box color="lightGray" borderStyle="raisedBottomShadow">
                    <Box paddingY={2} paddingX={5} display="flex" justifyContent="between">
                        <Box display="flex" direction="column">
                            <span className="faqHeader">
                                {question}
                            </span>
                            <Box paddingY={1}/>
                            {isOpen && (
                                <span className="faqAnswer">
                                    {answer}
                                </span>
                            )}
                        </Box>
                        <Box width={20}/>
                        <IconButton icon="arrow-down" onClick={() => setOpenItem(index)}/>
                    </Box>
                </Box>
            </Box>
        </TapArea>
    );
}

export function FaqSection(props) {
    // eslint-disable-next-line react/prop-types
    const { isTabletOrMobile } = props;
    const [openItem, setOpenItem] = React.useState(null)
    const questions = [
        {
            question: 'What are Creepy Creatures?',
            answer: 'Creepy Creatures are a unique collection of 13,000 NFTs living on the Ethereum blockchain. 13 characters in total make up our OG release with each character totaling 1,000 NFTs. All crafted by hand, courtesy of our amazingly talented artist Muggy.',
        },
        {
            question: 'Are Metamask wallets needed?',
            answer: (
                <div>
                    Yes. Metamask is a crypto wallet that stores your Ethereum for you. It is needed to purchase and mint your NFTs so you can become a Creepy Creatures holder. Your NFTs will be stored onto your wallet which has its own unique Ethereum address (e.g. 0x1234...ABCD). Learn more about Metamask Wallets and how easy they are to use <a href='https://metamask.io/' rel="noreferrer" target="_blank">here</a>.
                </div>
            )
        },
        {
            question: 'Where does my NFT go after I purchase a Creepy Creature?',
            answer: 'Your Creepy Creatures NFT will appear in whatever address, or connected wallet, you used to purchase the Creepy Creature. You can see your Creepy Creatures on OpenSea.'
        },
        {
            question: 'This sounds cool. How do I get involved?',
            answer: (
                <div>
                    If you&apos;re ready to dive in head first, <a href='https://discord.com/invite/creepy-creatures' rel="noreferrer" target="_blank">this</a> is a great place to start. Be prepared to participate in a fun and active community of Creepy Creatures enthusiasts! You should also follow us on <a href='https://twitter.com/CreepyOGs' rel="noreferrer" target="_blank">Twitter</a> if you want to keep up with what we&apos;re doing on a day to day basis and what events we have coming up. You don&apos;t have to be a Creepy Creatures holder to join us on Discord or Twitter but if you are then you&apos;ll get access to exclusive channels and lots of alpha. All are welcome to jump into the conversation, let us know your ideas and come hang out to chat with us!
                </div>
            )
        },
        {
            question: 'Do I have to own a tier to participate in the Creepy Creatures universe?',
            answer: "Ultimately, the decision is up to you whether you want to own 1 Creepy Creature or many, many more. The higher number of Creepy Creatures you hold the more cool perks and benefits that come along with it. You're going to LOVE the alpha we have in store for our tier holders (Especially our Legendary Tier holders) but even our brand new holders who have just one or a handful of Creepy Creatures will also be able to take advantage of exclusive drops and irl meetups that only Creepy Creatures holders can join in on. We're just Creepin' it real."
        }
    ];
    return (
        <Box display="flex" direction="column" alignItems='center'>
            <img src={faq} alt="Creepy creatures faqs" width={isTabletOrMobile ? 50: 200} height={isTabletOrMobile ? 38 : 150}></img>
            {questions.map((item, index) => formattedQuestion(item.question, item.answer, setOpenItem, index, openItem===index)) }
        </Box>
    )
}