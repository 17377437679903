import React from 'react';
import { Box } from 'gestalt';
import 'gestalt/dist/gestalt.css';
import twitterLogo from "./images/twitter.png";
import discordLogo from "./images/Discord-Logo-White.png";
import openSeaLogo from "./images/opensea.png";
import etherscanLogo from "./images/26205098.png"

export function ExternalLinksPanel() {
    return (
        <Box display="flex" alignItems='center'>
            <Box paddingX="1" marginEnd={1}>
                <a href="https://discord.com/invite/creepy-creatures">
                    <img src={discordLogo} alt="Discord" width={35} height={40}></img>
                </a>
            </Box>
            <Box paddingX="1">
                <a href="https://twitter.com/CreepyOGs">
                    <img src={twitterLogo} alt="Twitter" width={35} height={32}></img>
                </a>
            </Box>
            <Box paddingX="1">
                <a href="https://opensea.io/collection/creepy-ogs">
                    <img src={openSeaLogo} alt="OpenSea" width={35} height={35}></img>
                </a>
            </Box>
            <Box paddingX="1">
                <a href="https://etherscan.io/address/0xfa27d06d817205e6B3702594B996f3B0A476D022">
                    <img src={etherscanLogo} alt="Etherscan" width={35} height={35}></img>
                </a>
            </Box>
        </Box>
    )
}