/* eslint-disable no-debugger */
/* eslint-disable react/prop-types */
import React from 'react';
import './App.css';
import 'gestalt/dist/gestalt.css';
import firebase from "firebase/compat/app";
import "firebase/auth";
import "firebase/compat/database"
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import WrappedWagmiApp from './WrappedWagmiApp';

const firebaseConfig = {
  apiKey: "AIzaSyAKbrwO3G4ZjhDFJ5Er9YTl0_Sk28goocY",
  authDomain: "ccwebsite-f0512.firebaseapp.com",
  databaseURL: "https://ccwebsite-f0512-default-rtdb.firebaseio.com",
  projectId: "ccwebsite-f0512",
  messagingSenderId: "256591301642",
  appId: "1:256591301642:web:8f01c57feca164dc7ca3f3",
  measurementId: "G-98XEV6TPLD"
};

const app = firebase.initializeApp(firebaseConfig);
const { chains, publicClient } = configureChains(
  [mainnet],
  [alchemyProvider({ appId: 'zwU_aB5PmjsONL2FjLidHbU9KWTP1h4V' }), publicProvider()],
)

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains,
      options: {
        shimDisconnect: false,
        UNSTABLE_shimOnConnectSelectAccount: true,
      }}),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
  ],
  publicClient
});

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6LdaFqMoAAAAAN_D6ZJC_UH4Cuv_eCnFtGciq1nD'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <WrappedWagmiApp />
    </WagmiConfig> 
   )
}

export default App;

